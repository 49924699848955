import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Chart } from 'react-google-charts';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';

import moment from 'moment';

// Api
import { registerCustomMetric, getCustomMetric } from 'ajax/dashboard';
// State
import {
  dashboardFirstRun,
  dashboardMetric,
  dashboardChartData,
  dashboardRawData,
  dashboardCustomisation,
  dashboardDefaultData,
  dashboardRange,
  dashboardDate,
  dashboardAscending,
  dashboardLimit,
  dashboardGroup,
  dashboardOrder
} from 'state';

// Utils
import { toast } from 'react-toastify';
import { getDateNumberOfDaysBehind } from 'utils/getDateNumberOfDaysBehind';
import {
  getMonthStartEndDates,
  getLastYearDatesIso,
  getLastMonthDatesIso,
  getYearStartEndDates
} from 'utils/dateRangeSelector';

const options = {
  title: 'Default Title',
  width: '100%',
  responsive: true,
  height: '100%',
  legend: { position: 'bottom' },
  tooltip: { isHtml: true, trigger: 'visible' },
  chartArea: { width: '70%', height: '70%' }
};

const registerMetric = async ({ name, date, group, order, ascending, limit, field }) => {
  const payload = {
    name,
    field,
    from: date.from,
    to: date.to,
    group,
    order,
    ascending,
    limit
  };
  const request = await registerCustomMetric(payload);
  if (request.status !== 200) {
    toast.error('Error registering metric');
  }
  return request.data;
};

export default function SpendingChart({ dynamicChartProp }) {
  const [metric, setMetric] = useState(null);

  const [, setDashboardFirstRunState] = useRecoilState(dashboardFirstRun);
  const [, setMetricDashboard] = useRecoilState(dashboardMetric);
  const [, setChartData] = useRecoilState(dashboardChartData);
  const [, setRawData] = useRecoilState(dashboardRawData);
  const [, setCustomisation] = useRecoilState(dashboardCustomisation);
  const [, setDefaultData] = useRecoilState(dashboardDefaultData);
  const [, setRange] = useRecoilState(dashboardRange);
  const [, setDate] = useRecoilState(dashboardDate);
  const [, setAscending] = useRecoilState(dashboardAscending);
  const [, setLimit] = useRecoilState(dashboardLimit);
  const [, setGroup] = useRecoilState(dashboardGroup);
  const [, setOrder] = useRecoilState(dashboardOrder);

  const { data, isLoading, isRefetching } = useQuery(
    ['spend_metrics', metric?.identity],
    async () => {
      const request = await getCustomMetric(metric.identity);
      if (request.status !== 200) {
        return { chartData: [], tableColumns: [] };
      }

      let tableColumns = [];

      // Step 1: Identify all unique keys
      const allKeys = request.data.data.reduce((keys, item) => {
        Object.keys(item.Values).forEach((key) => {
          if (!keys.includes(key)) {
            keys.push(key);
          }
        });
        return keys;
      }, []);

      const chartType = request.data?.data[0]?.ValueType || '';

      // Step 2: Construct table columns
      tableColumns = ['Name', ...allKeys];

      if (chartType === 'Currency') {
        tableColumns.push({ type: 'string', role: 'tooltip', p: { html: true } });
      }

      // Step 3: Prepare chart data
      const chartData = request.data.data.map((item) => {
        // Initialize an array with 0 values for all keys
        const valuesArray = allKeys.map((key) => item.Values[key] || 0);

        if (chartType === 'Currency') {
          const keyValuePairs = Object.entries(item.Values)
            .map(
              ([key, value]) =>
                `<div>${key}: <b>£${value.toLocaleString('en-GB', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}</b></div>`
            )
            .join('');
          valuesArray.push(
            `<div class="p-3"><div class="mb-3 text-[13px]"><b>${item.Name}</b></div>${keyValuePairs}</div>`
          );
        }

        return [item.Name, ...valuesArray];
      });

      // Insert table columns as the first element in chartData
      chartData.unshift(tableColumns);

      return {
        chartData,
        tableColumns,
        chartTitle: request.data.name,
        chartType: request.data?.data[0]?.ValueType || ''
      };
    },
    {
      enabled: !!metric,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    const register = async () => {
      let dateToUse = { from: getDateNumberOfDaysBehind(1), to: getDateNumberOfDaysBehind(-1) };
      if (dynamicChartProp?.range) {
        switch (dynamicChartProp?.range?.toLowerCase()) {
          case 'today':
            dateToUse = {
              from: getDateNumberOfDaysBehind(0, false),
              to: getDateNumberOfDaysBehind(0, false)
            };
            setRange('Today');
            break;
          case 'yesterday':
            dateToUse = {
              from: getDateNumberOfDaysBehind(1, false),
              to: getDateNumberOfDaysBehind(1, false)
            };
            setRange('Yesterday');
            break;
          case 'thisMonth': {
            const monthDates = getMonthStartEndDates();
            dateToUse = {
              from: monthDates.startDate,
              to: monthDates.endDate
            };
            setRange('This month');
            break;
          }
          case 'thisYear': {
            const yearDates = getYearStartEndDates();
            dateToUse = {
              from: yearDates.startDate,
              to: yearDates.endDate
            };
            setRange('This year');
            break;
          }
          case 'last7Days':
            dateToUse = {
              from: getDateNumberOfDaysBehind(7),
              to: getDateNumberOfDaysBehind(0)
            };
            setRange('Last 7 days');
            break;
          case 'last30days':
            dateToUse = {
              from: getDateNumberOfDaysBehind(30),
              to: getDateNumberOfDaysBehind(0)
            };
            setRange('Last 30 days');
            break;
          case 'last90Days':
            dateToUse = {
              from: getDateNumberOfDaysBehind(90),
              to: getDateNumberOfDaysBehind(0)
            };
            setRange('Last 90 days');
            break;
          case 'lastWeek':
            dateToUse = {
              from: getDateNumberOfDaysBehind(7),
              to: getDateNumberOfDaysBehind(1)
            };
            setRange('Last week');
            break;
          case 'lastMonth': {
            const lastMonthDates = getLastMonthDatesIso();
            dateToUse = {
              from: lastMonthDates.startDate,
              to: lastMonthDates.endDate
            };
            setRange('Last month');
            break;
          }
          case 'lastYear': {
            const lastYearDates = getLastYearDatesIso();
            dateToUse = {
              from: lastYearDates.startDate,
              to: lastYearDates.endDate
            };
            setRange('Last year');
            break;
          }
          default:
            setRange('');
        }
      } else {
        dateToUse = {
          from: dynamicChartProp?.from || getDateNumberOfDaysBehind(1),
          to: dynamicChartProp?.to || getDateNumberOfDaysBehind(-1)
        };
      }
      const metric = await registerMetric({
        name: dynamicChartProp.name,
        date: dateToUse,
        group: dynamicChartProp.group,
        order: dynamicChartProp.order,
        ascending: dynamicChartProp.ascending,
        limit: dynamicChartProp.limit
      });
      if (metric) {
        setMetric(metric);
      }
    };

    register();
  }, []);

  const chartDataToUse = data?.chartData?.length > 1 ? data.chartData : [[], []];

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => navigate(`/dashboard/${dynamicChartProp.name}`)}
      onClick={() => {
        setDashboardFirstRunState(true);
        setMetricDashboard(null);
        setChartData([]);
        setRawData([]);
        setCustomisation('');
        setDefaultData(null);
        setRange('');
        setDate({ from: '', to: '' });
        setAscending('');
        setLimit('');
        setGroup('');
        setOrder('');
        navigate(`/dashboard/${dynamicChartProp.name}`);
      }}
      className="border p-4 min-h-[80px]"
    >
      <div className="relative">
        {(isLoading || isRefetching) && (
          <div className="inset-0 absolute z-10 rounded grid place-items-center">
            <span className="inset-0 absolute z-10 bg-black/10 rounded" />
            <span className="loader" />
          </div>
        )}
        {data?.chartData && data?.chartData?.length > 1 ? (
          <div className="relative">
            <div
              className={`absolute top-0 left-20 z-10 right-0${isLoading || isRefetching ? 'bg-black/10' : 'bg-white'}`}
            >
              <p className="text-sm font-semibold">{data?.chartTitle}</p>
              <p className="text-xs text-gray-500 font-semibold">
                {timeFormatter(dynamicChartProp.from)} - {timeFormatter(dynamicChartProp.to)}
              </p>
            </div>
            <div className="h-[400px] md:h-[600] lg:h-[800] xl:h-[1000]">
              <Chart
                chartType="BarChart"
                data={chartDataToUse}
                options={{
                  ...options,
                  title: '',
                  hAxis: {
                    format: data?.chartType === 'Currency' ? '£#,##0.00' : ''
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <p>
            <center>No chart data available for the selected period</center>
          </p>
        )}
      </div>
    </div>
  );
}

SpendingChart.propTypes = {
  dynamicChartProp: PropTypes.object
};

function timeFormatter(date) {
  const rawDate = moment(date);
  const londonTime = rawDate.tz('Europe/London').format('DD/MM/YYYY');
  return londonTime;
}
