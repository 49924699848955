import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useRecoilState } from 'recoil';
import { Router } from '@reach/router';

import Layout from 'layout';
import Seo from 'components/Seo';
import PrivateRoute from 'components/PrivateRoute';
// UI
import LoadingPage from 'ui/LoadingPage';
// Import from State
import { profileState, isLoggedIn as loginState, configurationState, maintenanceState } from 'state';
// Auth Function
import { checkLoginStatus } from 'func/auth';
// Charts
import SpendingChart from 'components/Charts/SpendingChart';
import AllCharts from 'components/Charts/AllCharts';

function ChartsPage() {
  const [profile, setProfile] = useRecoilState(profileState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [, setConfiguration] = useRecoilState(configurationState);
  const [maintenance, setMaintenance] = useRecoilState(maintenanceState);

  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    setMaintenance({
      online: profile.options.maintenance.online,
      message: profile.options.maintenance.message || 'This application is currently offline for maintenance.',
      isAdmin: profile.role.value === 'Administrator'
    });
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    if (isLoggedIn) return;
    initialize();
  }, []);

  return (
    <>
      <Seo title="Dashboard - Veezu" />
      <LoadingPage hide={isLoggedIn} />
      {profile && (
        <Layout
          showLogoutButton
          isMaintenanceMode={!maintenance.online}
          isAdmin={profile?.role?.value === 'Administrator'}
          isConfigRoute={false}
        >
          <div className="w-full">
            <div className="pl-4 pr-4 pt-6 md:pl-8 md:pr-8 xl:pr-16">
              <div className="relative">
                <React.Suspense fallback="">
                  <Router>
                    <PrivateRoute
                      path="/dashboard"
                      component={AllCharts}
                      isAllowed={
                        (profile?.role?.value === 'Administrator' ||
                          profile?.role?.value === 'SuperUser' ||
                          profile?.role?.value === 'Auditor' ||
                          profile?.role?.value === 'Controller' ||
                          profile?.role?.value === 'Manager' ||
                          profile?.role?.value === 'Supervisor') &&
                        profile?.options?.general?.dashboard
                      }
                      navUrl={profile?.options?.defaults?.module ? profile.options.defaults.module : '/404'}
                    />
                    <PrivateRoute
                      path="/dashboard/:name"
                      component={SpendingChart}
                      isAllowed={
                        (profile?.role?.value === 'Administrator' ||
                          profile?.role?.value === 'SuperUser' ||
                          profile?.role?.value === 'Auditor' ||
                          profile?.role?.value === 'Controller' ||
                          profile?.role?.value === 'Manager' ||
                          profile?.role?.value === 'Supervisor') &&
                        profile?.options?.general?.dashboard
                      }
                      navUrl={profile?.options?.defaults?.module ? profile.options.defaults.module : '/404'}
                    />
                    <PrivateRoute
                      path="/dashboard/:customisation/:name"
                      component={SpendingChart}
                      isAllowed={
                        (profile?.role?.value === 'Administrator' ||
                          profile?.role?.value === 'SuperUser' ||
                          profile?.role?.value === 'Auditor' ||
                          profile?.role?.value === 'Controller' ||
                          profile?.role?.value === 'Manager' ||
                          profile?.role?.value === 'Supervisor') &&
                        profile?.options?.general?.dashboard
                      }
                      navUrl={profile?.options?.defaults?.module ? profile.options.defaults.module : '/404'}
                    />
                  </Router>
                </React.Suspense>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default ChartsPage;
